.detailPageHead {
  padding: 60px 0;
  text-align: left;

  h1 {
    font-family: 'Roboto';
    font-size: 48px;
    font-weight: 300;
    line-height: 56px;
    text-align: left;

  }

  @media (max-width: 786px) {
    padding: 20px 0;

    h1 {
      color: #ffffff;
      font-family: Roboto;
      font-size: 48px;
      font-weight: 300;
      line-height: 56px;
      text-align: left;
    }
  }
}

.wrapLocationRating {
  display: flex;

  @media (max-width: 786px) {
    flex-wrap: wrap;
  }
}

.banLocation {
  background: #fff;
  line-height: 39px;
  font-weight: 800;
  font-size: 20px;
  padding: 0 10px;
  border-radius: 5px;
  display: inline-flex;
  align-items: center;

  span {
    background: linear-gradient(262.84deg, #b82d35 14.11%, #03437a 87.33%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-background-clip: text;
    -moz-text-fill-color: transparent;
  }

  &::before {
    content: "";
    width: 20px;
    height: 26px;
    background: url(../img/locationPin.svg) no-repeat;
    margin-right: 9px;
  }

  @media (max-width: 786px) {
    font-size: 14px;
    line-height: 28px;

    &::before {
      width: 10px;
      height: 14px;
      background-size: 10px;
    }
  }
}

.rating {
  padding-left: 18px;

  label {
    font-weight: 300;
    font-size: 18px;

    strong {
      font-weight: 800;
    }
  }

  @media (max-width: 786px) {
    width: 100%;
    padding-left: 0;
    margin-top: 7px;

    label {
      font-size: 14px;
    }
  }
}

.storeDetailWrap {
  .container {
    flex-wrap: wrap;
  }
}

.storeDetail {
  background: #ffffff;
  // box-shadow: 0px 0px 7px 3px rgba(0, 0, 0, 0.06);
  border-radius: 5px;
  width: 100%;
  padding: 0 40px;
  position: relative;

  &::before {
    // content: "";
    width: 27px;
    height: 27px;
    border-radius: 50%;
    background: #f5f7fe;
    position: absolute;
    top: 30px;
    left: -13.5px;
    box-shadow: inset -8px 0 5px rgba(0, 0, 0, 0.05);
  }

  &::after {
    // content: "";
    width: 27px;
    height: 27px;
    border-radius: 50%;
    background: #f5f7fe;
    position: absolute;
    top: 30px;
    right: -13.5px;
    // box-shadow: inset 8px 0 5px rgba(0, 0, 0, 0.05);
  }

  @media (max-width: 768px) {
    padding: 0 15px;

    &::before {
      width: 19px;
      height: 19px;
      left: -9.5px;
    }

    &::after {
      width: 19px;
      height: 19px;
      right: -9.5px;
    }
  }
}

.stTopsection {
  width: 100%;
  display: flex;

  @media (max-width: 768px) {
    flex-wrap: wrap;
  }

  .sttLeft {
    width: 50%;
    padding: 35px 40px 35px 0;

    @media (max-width: 768px) {
      width: 100%;
      padding: 0;
      order: 2;

      .mobileSlider.bigarrow {
        margin-bottom: 70px;

        .slick-arrow.slick-prev {
          left: 0;
        }

        .slick-arrow.slick-next {
          right: 0;
        }

        .slick-track {
          display: flex;
          background: #f4f5fc;

          .slick-slide {
            height: inherit;

            >div {
              height: 100%;

              .spmItems {
                height: 100%;

                img {
                  object-fit: contain;
                  width: 100%;
                  height: 100%;
                  object-position: center center;
                }
              }
            }
          }
        }
      }
    }
  }

  .lcHead {
    flex-direction: column;
    margin-top: 0;

    p {
      color: #000000;
      font-family: 'Roboto';
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0.005em;
      text-align: left;
    }

    h2 {
      font-family: 'Roboto';
      font-size: 24px;
      font-weight: 500;
      line-height: 36px;
      letter-spacing: 0.01em;
      text-align: left;
      color: #7F234F;
    }
  }

  .storeAdress {

    // margin-top: 30px;
    h3 {
      font-family: 'Roboto';
      font-size: 24px;
      font-weight: 500;
      line-height: 36px;
      letter-spacing: 0.01em;
      text-align: left;
      color: #7F234F;

    }

    p {
      color: #808080;
      line-height: 22px;
    }
  }
}

.whiteCall {
  width: 23px;
  height: 23px;
  display: inline-flex;
  background: url(../img/callWhite.svg);
  margin-right: 15px;

  @media (max-width: 786px) {
    width: 16px;
    height: 16px;
    background-size: 16px;
  }
}

.sttRight {
  width: 50%;
  padding: 35px 0 35px 40px;

  @media (max-width: 768px) {
    width: 100%;
    padding: 0;
    order: 1;
  }
}

.shareDistance {
  display: flex;
  justify-content: right;

  .storeDistance {
    margin-bottom: 0;
  }

  // .sharewrap {
  //   margin-left: 11px;
  //   padding-left: 11px;
  //   border-left: solid 1px #b5b5b5;
  // }

  @media (max-width: 768px) {
    justify-content: space-between;
    margin: 25px 0 20px;
  }
}

.sharewrap {
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  color: #000000;
  display: inline-flex;
  align-items: center;
  line-height: 28px;
  cursor: pointer;
  position: relative;

  &:after {
    content: "";
    width: 20px;
    height: 20px;
    display: inline-block;
    background: url(../img/shareIcon.svg) no-repeat;
    margin-left: 7px;
  }

  .socialList {
    position: absolute;
    top: 120%;
    right: -10px;
    display: flex;
    flex-direction: column;
    background: #0076ce;
    align-items: center;
    border-radius: 5px;
    opacity: 0;
    visibility: hidden;
    transition: 0.3s;

    a {
      padding: 7px !important;

      em {
        margin: 0;
      }
    }
  }

  &:hover {
    .socialList {
      top: 100%;
      opacity: 1;
      visibility: visible;
    }
  }
}


.grHead {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 33px;
}

.writeReviewBtn {
  font-weight: 500;
  font-size: 18px;
  line-height: 36px;
  text-decoration: underline;
  color: #000000;

  &:after {
    content: "";
    width: 16px;
    height: 16px;
    margin-left: 14px;
    background: url(../img/writeReviews.svg) no-repeat;
    display: inline-block;
  }

  @media (max-width: 786px) {
    font-weight: 500;
    font-size: 15px;
    line-height: 36px;
  }
}

.grList {
  display: flex;
  width: calc(100% + 30px);
  margin: 0 -15px;
  justify-content: space-between;

  .slick-prev {
    left: -20px;

    @media (max-width: 500px) {
      left: -10px;
    }
  }

  .slick-next {
    right: -20px;

    @media (max-width: 500px) {
      right: -10px;
    }
  }

  .slick-track {
    display: flex !important;

    .slick-slide {
      height: inherit;
      padding: 0 15px;

      >div {
        height: 100%;

        .grItem {
          height: inherit;
        }
      }
    }
  }

  .grItem {
    display: inline-flex;
    width: 24%;
    background: #F0F0F0;
    border-radius: 2px;
    padding: 14px;
    border: 1px solid #D2D2D2;

    .giHead {
      display: flex;
      width: 100%;
      justify-content: space-between;
      margin-bottom: 20px;

      .starRating {
        align-self: center;
      }

      strong {
        background: #6ea204;
        border-radius: 5px;
        font-weight: 500;
        font-size: 26px;
        line-height: 38px;
        color: #fff;
        align-self: center;
        padding: 0 5px;
      }

      .giLeft {
        width: 100%;
        flex-direction: column;
        display: inline-flex;

        h3 {
          color: #000000;
          font-weight: 500;
          font-size: 15px;
          line-height: 21px;
        }

        i {
          color: #000000;
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 21px;
        }
      }
    }

    p {
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      margin-top: auto;
    }

    @media (max-width: 1024px) {
      width: 296px;
    }
  }
}

.submitBtncheckobx {
  display: flex;
  justify-content: space-between;
  margin-top: 45px;

  @media (max-width: 786px) {
    flex-direction: column;

    label {
      margin: 15px 0;
    }
  }
}

.checkboxWrap {
  display: flex;
  font-weight: 400;
  font-size: 15px;
  line-height: 21px;
  color: #808080;
  width: 85%;
  max-width: 750px;

  input {
    display: none;
  }

  >span {
    width: 25px;
    min-width: 25px;
    margin-right: 13px;
    height: 25px;
    background: #DDDDDD;
    border: 1px solid #BEBEBE;
    box-sizing: border-box;
    border-radius: 5px;
    position: relative;

    &:after {
      content: "";
      position: absolute;
      top: 40%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(38deg);
      width: 6px;
      height: 14px;
      border-bottom: solid 1px #000;
      border-right: solid 1px #000;
      opacity: 0;
    }
  }

  input:checked+span:after {
    opacity: 1;
  }

  @media (max-width:786px) {
    font-size: 11px;
    line-height: 1;
    width: 100%;
  }
}

.enqueryForm {
  padding: 32px 26px;
  background: #D9F5FD;
  border-bottom: solid 5px #7F234F;
  position: relative;


  &.signuppopup {
    position: fixed;
    bottom: 0;
    width: 100%;
    // max-width: 1236px;
    max-width: 760px;
    z-index: 15000;
    background: #F1F1F1;
    border: 2px solid #FFFFFF;
    box-sizing: border-box;
    box-shadow: inset 0px 0px 12px rgba(0, 0, 0, 0.25);
    border-radius: 27px 27px 0 0;

    h2 {
      color: #6E2585;
      // padding-right: 30px;
      font-size: 14px;
    }

    .efListwrap {
      flex-wrap: wrap;

      li {
        // width: 33.33%;
        // margin-bottom: 25px;

        label {
          background: transparent;
        }

        &.fullwidth {
          // width: 66.67%;
        }
      }

      @media (max-width:786px) {
        // flex-direction: unset;

        li {
          width: 50%;
          padding-right: 10px;
          margin-bottom: 0px;
          padding-bottom: 10px;

          &.fullwidth {
            width: 100%;
            text-align: left;
          }

          input {
            height: 45px;
            padding: 10px;
          }
        }
      }
    }

    .crossBtn {
      top: 15px;
      right: 15px;
      cursor: pointer;
    }
  }

  @media (max-width: 786px) {
    padding-bottom: 0;
  }
}

.crossBtn {
  background: url(../img/crossbtn.svg) no-repeat center center;
  width: 50px;
  height: 50px;
  top: 0;
  right: 0;
  position: absolute;
}

.thankYoumsg {
  font-family: 'Roboto';
  h3 {

      font-weight: 300;
      text-align: left;
      font-size: 60px;
      line-height: 70px;
      color: #00468B;
      @media screen and (max-width:700px) {
        padding: 0 50px;
      }
  }

  h6{
font-size: 32px !important;
font-weight: 300;
line-height: 40px;
text-align: center;
color: #000000;

  }

  p{

font-size: 24px;
font-weight: 400;
line-height: 32px;
text-align: left;
color: #000000;

  }
}


.efListwrap {
  width: 100%;
  // margin-top: 15px;
  display: flex;
  padding: 0;
  align-items: center;

  li {
    width: calc(33.33% - 55px);

    @media (max-width: 786px) {
      padding-right: 15px;
      padding-bottom: 15px;
    }

    &:last-child {
      display: flex;
      align-items: flex-end;

      @media (max-width: 786px) {
        width: 100%;
        justify-content: center;
        // text-align: center;

        .gradientBtn {
          font-size: 14px;
          line-height: 16px;
          padding: 11px 50px;
        }
      }
    }

    span {
      position: relative;
      display: block;
      width: 100%;

      label {
        // padding: 0 11px;
        line-height: 20px;
        background: #ebf9fc;
        border-radius: 3px;
        margin-bottom: -13px;
        font-size: 12px;
      }

      input {
        border-radius: 0px;
        background: #ffffff;
        border: 1px solid #c2d5d9;
        height: 63px;
        width: 100%;
        padding: 0 22px;
        font-weight: 500;
        font-size: 14px;

        @media (min-width: 800px) {
          height: 40px;
        }
      }
    }

    .gradientBtn {
      font-weight: 700;
      font-size: 20px;
      line-height: 23px;
      display: inline-flex;
      padding: 19px 44px;
    }
  }

  @media (max-width: 786px) {
    flex-direction: column;

    li {
      width: 100%;
      padding-bottom: 20px;
      padding-right: 0;

      &:last-child {
        padding-bottom: 30px;
        padding-top: 10px;
      }
    }
  }
}

.efListwrap>* {
  flex: 1;
  margin-left: 1.3%;
  min-width: 0;
}

.efListwrap>*:first-child {
  margin-left: 0;
}

.sotreListwrap {
  padding: 60px 0 30px 0;
  position: relative;
}

.storeList {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 25px;

  &.fourCol {
    li {
      width: 25%;

      img {
        height: 270px;
      }

      h3 {
        font-size: 24px;
        line-height: 30px;
        margin-top: 30px;
      }
    }
  }

  li {
    width: 25%;
    padding: 20px 15px;
    margin-bottom: 10px;
    text-align: left;
    border-left: solid 1px #AAA;

    &+li {
      border-left: solid 1px #AAA;
    }

    h3 {
      font-weight: 700;
      font-size: 20px;
      line-height: 26px;
    }

    .btnwrap {
      display: block;

      .gradientBtn {
        display: block;
        font-weight: 700;
        text-align: center;
        font-size: 20px;
        margin-top: 20px;
        line-height: 23px;
      }
    }

    p {
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      /* or 150% */
    }

    span {
      font-weight: 300;
      font-size: 16px;
      line-height: 30px;
    }

    img {
      width: 100%;
      // height: 109px;
      object-fit: contain;
    }
  }

  // @media (max-width: 768px) {
  //   flex-wrap: wrap;
  //   li {
  //     width: 33.33%;
  //   }
  //   &.fourCol {
  //       li {
  //           width: 25%;
  //           img {
  //               height: 270px;
  //           }
  //           h3 {
  //               font-size: 24px;
  //               line-height: 30px;
  //               margin-top: 30px;
  //           }
  //       }
  //   }
  //   li {
  //       width: 20%;
  //       padding: 20px 10px;
  //       text-align: center;
  //       h3 {
  //           font-weight: 400;
  //           font-size: 15px;
  //           line-height: 20px;
  //       }
  //       img {
  //           width: 100%;
  //           height: 109px;
  //           object-fit: contain;
  //       }
  //   }
  @media (max-width: 768px) {
    flex-wrap: wrap;

    li {
      width: 33.33%;
    }

    &.fourCol {
      li {
        width: 50%;

        img {
          height: 170px;
        }

        h3 {
          font-size: 13px;
        }
      }
    }
  }

  @media (max-width: 600px) {
    flex-wrap: wrap;

    li {
      width: 50%;
      border-left: 0 !important;

    }

    &.fourCol {
      li {
        img {
          height: 170px;
        }

        width: 50%;
      }
    }
  }

  @media (max-width: 500px) {
    flex-wrap: wrap;

    li {
      width: 100%;
      border: 0 !important;
    }

    &.fourCol {
      li {
        img {
          height: 170px;
        }

        width: 50%;
      }
    }
  }
}

.prodNoffer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 30px;

  li {
    width: 49%;
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    line-height: 0;

    &:after {
      content: "";
      position: absolute;
      width: 100%;
      height: 100px;
      opacity: 0.7;
      background: linear-gradient(0deg, #000000 30.73%, rgba(196, 196, 196, 0) 100%);
      left: 0;
      bottom: 0;
    }

    img {
      width: 100%;
      height: 241px;
      object-fit: cover;
    }

    h3 {
      color: #fff;
      position: absolute;
      bottom: 0;
      left: 0;
      padding: 10px;
      font-weight: 900;
      font-size: 28px;
      line-height: 30px;
      max-width: 300px;
      z-index: 1;
    }
  }

  @media (max-width: 768px) {
    flex-wrap: wrap;

    li {
      width: 100%;

      &+li {
        margin-top: 10px;
      }
    }
  }
}

ul.faqAccordion {
  position: relative;
  display: block;
  width: 100%;
  height: auto;
  padding: 20px 0;
  margin: 0;
  list-style: none;

  li {
    position: relative;
    display: block;
    width: 100%;
    height: auto;
    padding: 20px 0;
    margin: 0;
    border-bottom: 1px solid #d9d9d9;
    cursor: pointer;

    // &:hover {
    //   h3 {
    //     color: #6e2585;
    //   }
    // }

    &.active {
      h3 {
        em {

          //background: #6e2585;
          &:before {
            transform: translate(-50%, -50%) rotate(0deg);
          }
        }
      }
    }

    h3 {

      position: relative;
      display: block;
      width: 100%;
      height: auto;
      padding: 0;
      margin: 0;
      font-size: 16px;
      line-height: 24px;
      cursor: pointer;
      color: #0E0E0E;
      padding-right: 30px;
      font-family: 'Roboto', sans-serif;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0.005em;
      text-align: left;


      em {
        width: 19px;
        height: 19px;
        //background: #808080;
        border-radius: 2px;
        position: absolute;
        right: 0;
        top: 0;
        transition: all 0.3s ease-in-out;

        &::after {
          content: "";
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 11px;
          height: 2px;
          background: #6e2585;
        }

        &::before {
          content: "";
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%) rotate(90deg);
          width: 11px;
          height: 2px;
          background: #6e2585;
          transition: 0.3s;
        }
      }
    }

    div.answer {
      position: relative;
      display: block;
      width: 100%;
      height: auto;
      margin: 0;
      padding: 0;
      cursor: pointer;

      span {
        font-family: 'Roboto', sans-serif;
        font-size: 16px;
        font-weight: 400 !important;
        line-height: 20px;
        letter-spacing: 0.005em;
        text-align: left;
        color: #6E6E6E !important;
      }


      p {
        position: relative;
        display: block;
        font-weight: 300;
        padding: 10px 0 0 0;
        cursor: pointer;
        margin: 0;
        font-weight: 300;
        font-size: 14px;
        line-height: 22px;
      }
    }
  }
}

.mobileSlider {
  display: none;
}

@media (max-width: 768px) {
  .mobileSlider {
    display: block;
  }
}

.photoGalleryDesktop {
  h4 {
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    margin: 12px 0;
  }

  @media (max-width: 768px) {
    display: none;
  }
}

.pgCoverImage {
  width: 100%;
  height: 306px;
  overflow: hidden;

  .pgItem {
    display: none;
    width: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover !important;
      border-radius: 5px;
      overflow: hidden;
    }
  }

  @media (max-width: 768px) {
    display: none;
  }
}

  @media (min-width: 768px) and (max-width: 1024px) {

    .pgCoverImage {
      height: 277px !important;
    }

    .pgItem {
      display: none;
      width: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover !important;
      border-radius: 5px;
      overflow: hidden;
     }
    }
  }


.pgListToggle {
  display: flex;
  justify-content: center;
  margin-top: 12px;

  li {
    width: 23%;
    cursor: pointer;
    opacity: 0.8;
    margin-right: 5px;
    padding: 0 5px;

    &.active {
      opacity: 1;
    }

    img {
      width: 100%;
      height: 68px;
      border-radius: 5px;
      overflow: hidden;
      object-fit: cover;
    }
  }

  .faqWrap {
    padding-top: 50px;
  }

  .storePhotosMobile {
    .slick-slide {
      padding: 10px;
    }
  }
}

.dds__form__field {
  margin-top: 10px;
}
